import { TOAST_CLOSE_TIME } from "constants/Toasts";
import { ORDER_STATUS_MAP } from "constants/Orders";
import { FC, useCallback, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useAppSelector, useNavigate, useSoundMessageNotifications, useTranslate } from "hooks";
import { userSelectors } from "slices/userSlice";
import GSChatService from "services/GSChatService";
import TranslationService from "services/TranslationService";
import "react-toastify/dist/ReactToastify.css";
import { MessageToast } from "components/Messages/components";
import { Markdown } from "_ui-kit/components/Markdown";

export const Toasts: FC = observer(() => {
  const currentUser = useAppSelector(userSelectors.currentUser);
  const { navigate } = useNavigate();
  const { notify } = useSoundMessageNotifications();
  const { translate } = useTranslate();

  const onNewChatMessage = useCallback(async ({ message: original }) => {
    const { message } = await GSChatService.client.getMessage(original.id);

    if (currentUser && message.user.id !== currentUser.id) {
      const order = message.attachments[0]?.type === "order" ? message.attachments[0].order : null;

      const text =
        message.i18n?.[`${TranslationService.currentLang}_text`] ||
        message.i18n?.en_text ||
        message.text ||
        (order ? `${translate("orders.statusTitle")}: ${ORDER_STATUS_MAP(translate)[order.status]}` : '');

      await GSChatService.updateUnreads();
      await notify();

      toast(
        <MessageToast title={message.user.name}>
          <Markdown source={text} />
        </MessageToast>, {
          onClick: () => {
            navigate(`orders?chat_id=${message.cid.split(':')[1] || message.cid}`);
          }
        });
    }
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      await GSChatService.subscribe("message.new", onNewChatMessage);
    })();


    return () => {
      (async () => {
        await GSChatService.unsubscribe("message.new", onNewChatMessage);
      })();
    };
  }, [GSChatService.client]);

  return (
    <StyledToastContainer stacked hideProgressBar autoClose={TOAST_CLOSE_TIME} />
  );
});

const StyledToastContainer = styled(ToastContainer)`
  &.offset {
    .Toastify__toast {
      flex-direction: column-reverse;
    }

    .Toastify__close-button {
      align-self: end;
      margin-bottom: 8px;
    }
  }
  
  .Toastify__toast {
    background: ${({ theme }) => theme.palette.background.inverted};
    color: ${({ theme }) => theme.palette.textIcon.baseInverted};
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
  }

  .Toastify__toast-body {
    overflow: hidden;
  }
  
  .Toastify__toast-body > div {
    max-width: 100%;
  }

  .Toastify__toast--default {}
  
  .Toastify__toast--error {
    border: 1px solid ${({ theme }) => theme.palette.stroke.extraError};
  }
  
  .Toastify__toast--info {
    border: 1px solid ${({ theme }) => theme.palette.stroke.extraInfo};
  }
  .Toastify__toast--success {
    border: 1px solid ${({ theme }) => theme.palette.stroke.extraSuccess};
  }
  
  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.textIcon.baseInverted};
  }
`;

import { action, makeObservable, observable, computed } from "mobx";
import { ThemeName } from "types";
import { initializeTheme } from "themes/index";
import { Theme } from "../types/theme";

const urlParams = new URLSearchParams(window.location.search);
const storageTheme = urlParams.get("theme") || window.localStorage.getItem("theme");
const defaultTheme = storageTheme === ThemeName.Dark || storageTheme === ThemeName.Light ? storageTheme : ThemeName.Light;

class ThemeStore {
  @observable private themeName: ThemeName = defaultTheme;
  @observable private theme: Theme | null = null;
  @observable private isLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get isLoadingTheme() {
    return this.isLoading;
  }

  @computed
  public get activeThemeName() {
    return this.themeName;
  }

  @computed
  public get activeTheme() {
    return this.theme;
  }

  @action
  public setTheme = async (theme: ThemeName) => {
    this.isLoading = true;
    this.theme = await initializeTheme(theme);
    this.themeName = theme;
    window.localStorage.setItem("theme", theme);
    this.isLoading = false;
  };
}

export default new ThemeStore();

import { RequestMethod } from "client/BaseHttpClient";
import { LocationRawData } from "types/location";
import Client from "./Client";

interface LocationResponse {
  items: LocationRawData[];
}

export const getAutocomletedLocationList = (locationText: string): Promise<LocationResponse> =>
  Client.doRequest(`/api/v1/locations/autocomplete?text=${decodeURIComponent(locationText)}`, {
    method: RequestMethod.GET,
  });

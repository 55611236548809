import { PaymentTypes, paymentMethodDictionary, paymentTypeDictionary } from "constants/Transaction";
import { TransactionStatuses } from "types/transaction";
import { convertCentsToDollars } from "utils/money";

enum TransactionOperationTypes {
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
  DONATION = "donation",
  STREAM_PAYMENT = "stream_payment",
  ORDER = "order",
  ORDER_PROLONGATION = "order_prolongation"
}

interface TransactionDestinationRaw {
  destination_payment_method: string;
  avatar_url?: string;
  username?: string;
}

interface TransactionOperation {
  id: TransactionOperationTypes;
  ref?: string;
}

export interface TransactionRawData {
  amount: number;
  net_amount: number;
  fee: number;
  destination: TransactionDestinationRaw;
  id: string;
  status: TransactionStatuses;
  timestamp: number;
  type: string;
  updated_at: string;
  created_at: string;
  description?: string;
  operation: TransactionOperation
}

export interface TransactionDestination {
  paymentMethod: PaymentMethod;
  username: string;
  avatarUrl: string;
}

export interface PaymentMethod {
  name: string;
  icon: React.ReactElement;
}
export class Transaction {
  amountInCents: number;
  amountNetInCents: number;
  amountFeeInCents: number;
  destination: TransactionDestination;
  transactionId: string;
  status: TransactionStatuses;
  date: Date;
  paymentType: PaymentTypes;
  description: string;
  operation: TransactionOperation;
  amountInDollars: number;

  constructor(data: TransactionRawData) {
    this.destination = {
      paymentMethod: paymentMethodDictionary[data.destination?.destination_payment_method],
      username: data.destination?.username,
      avatarUrl: data.destination?.avatar_url,
    };

    this.amountInCents = data.amount;
    this.amountFeeInCents = data.fee;
    this.amountNetInCents = data.net_amount;
    this.transactionId = data.id;
    this.status = data.status;
    const date = data.timestamp || data.updated_at || data.created_at;
    this.date = new Date(date);
    this.paymentType = paymentTypeDictionary[data.type];
    this.description = data.description;
    this.operation = data.operation;
    this.amountInDollars = convertCentsToDollars(this.amountInCents);
  }
}

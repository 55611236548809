import { Environment } from "../types/common";

export const ENV = process.env.ENV || Environment.DEV;

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const BASE_URL = process.env.BACKEND_DOMAIN ? `https://${process.env.BACKEND_DOMAIN}` : "https://api-dev.mpax.co";

export const WS_URL = process.env.WS_DOMAIN || "ws://ws-dev.mpax.co/ws";

export const EMBEDDING_VIDEO_URL = process.env.EMBEDDING_VIDEO_URL
  ? process.env.EMBEDDING_VIDEO_URL
  : // : "https://web-plugin.dev.metapax-api.com/";
    "http://localhost:3001/";

export const PUBLISHABLE_KEY = process.env.PUBLISHABLE_KEY
  ? process.env.PUBLISHABLE_KEY
  : "pk_test_51O9OgCLpAF9dQP3Uez9Do1KwkKsUbPPQRUt38xCbS6Jn6fcM1dIXgNBBPXBDWEsPdFKgeNuklnNKMbAlEodwIJw000vhOUn3UV";

export const NEW_PAXER_GOOGLE_SHEETS_URL =
  "https://script.google.com/macros/s/AKfycbwVnVNdOCweeC09PXi-0Sv6DB85aijM46k9yiiLo9uUwqPI4Qdi21fPZ6vgvBOkGYCE8g/exec";

export const WITHDRAW_GOOGLE_SHEETS_URL =
  "https://script.google.com/macros/s/AKfycbxfV6dXJnUq-e9lJDQ71AxtH2ltZplMjBbbZA6AJdZ_gCGykgg2tKbL2ze8rDfrXgetog/exec";

export const FEEDBACK_GOOGLE_SHEETS_URL =
  "https://script.google.com/macros/s/AKfycbz6u5egi6M862Lgv1RzNFa4o7l5L6ODXktthJCAF0IadSH8SGdFy9Uvy6XpXb1RmGV7/exec";

export const ACCOUNT_DELETE_REQUEST_SHEETS_URL =
  "https://script.google.com/macros/s/AKfycbyhtJRiExYk1WZi0ypopdnm2xKX4P8Ba8QeVJKVUYfQPESpQ12Hb4YWgXuzwn2Je5b7/exec";

export const ANALYTICS_API_KEY = process.env.ANALYTICS_API_KEY ? process.env.ANALYTICS_API_KEY : "e1f920e9248e4fc994a40cddc8717895";

export const GETSTREAM_CHAT_API_KEY = process.env.GETSTREAM_CHAT_API_KEY ? process.env.GETSTREAM_CHAT_API_KEY : "tcqhwyu4xb9x";

export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID ? process.env.INTERCOM_APP_ID : "r1fj7mcv";

export const IS_PAYMENT_AVAILABLE = process.env.IS_PAYMENT_AVAILABLE ? process.env.IS_PAYMENT_AVAILABLE : true;

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY
  ? process.env.GOOGLE_MAPS_API_KEY
  : "AIzaSyAZKE33U71qMFUG8Mcg92VrfoY94T18n5s";

export const MAPBOX_API_KEY = process.env.MAPBOX_API_KEY
  ? process.env.MAPBOX_API_KEY
  : "pk.eyJ1IjoibXBheDEiLCJhIjoiY20yeDRxbXNuMDB3ZzJscXpmZTQxNnR0cyJ9.byXVs0lWSI0bkr5ez8DkfQ";

export const AGORA_APP_ID = process.env.AGORA_APP_ID || "7649892c21394feca0b19ca85b580d82";

export const GOOGLE_OAUTH_CLIENT_ID = process.env.GOOGLE_OAUTH_CLIENT_ID || "";
// "894756077453-brdrnkrgct85ceithoem5qrf9mgss2be.apps.googleusercontent.com";

export const FACEBOOK_AUTH_APP_ID = process.env.FACEBOOK_AUTH_APP_ID || "818494183807628";

export const APPLE_AUTH_CLIENT_ID = process.env.APPLE_AUTH_CLIENT_ID || "";

export const IS_SOCIAL_NETWORKS_AUTH_AVAILABLE = process.env.IS_SOCIAL_NETWORKS_AUTH_AVAILABLE || "false";

export const TWITTER_CONSUMER_KEY = process.env.TWITTER_CONSUMER_KEY || "M7p837VweCCjQJ7loVGncI9PM";

export const TWITTER_CONSUMER_SECRET = process.env.TWITTER_CONSUMER_SECRET || "BIf2nAX7Z7qhshO0kSIx68sXIv7OgKpPtKdh7ieYzO3mXvO3xm";

export const TELEGRAM_BOT_NAME = process.env.TELEGRAM_BOT_NAME || "mpax_dev_bot";

export const APPSTORE_LINK = "https://apps.apple.com/app/mpax/id6593687495";

export const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.mpax.android";
